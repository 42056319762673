import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import _ from 'lodash'
import CitySelector from './CitySelector';
import TrebGrid from './TrebGrid';
import TrebTable from './TrebTable';
//import Demo from './Demo'
import Map from './Map'
import { Typography, IconButton, Slider, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import ViewWeekOutlinedIcon from '@material-ui/icons/ViewWeekOutlined';
import ViewListOutlinedIcon from '@material-ui/icons/ViewListOutlined';
import ViewListIcon from '@material-ui/icons/ViewList';
//import useStyles from './styles';
import dateToTime from './helpers/dateToTime';
import chooseDate from './helpers/chooseDate';
import LoadingCards from './LoadingCards';
import CitySelectorLoading from './CitySelectorLoading';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './styles';

const PriceSlider = withStyles({
  valueLabel: {
    fontSize: '0.70rem',
    left: -14,
    '& > span': {
      width: '40px',
      height: '40px',
      '&::before': {
        width: '40px',
        height: '40px',
      }
    }
  }
})(Slider);

const Landing = ({ user, signedIn }) => {
  const [currentListings, setCurrentListings] = useState([]);

  const [cityState, setCityState] = useState(user?.defaultCheckedCities || ['Kitchener', 'Waterloo', 'Cambridge', 'Guelph', 'Puslinch'])// "Flamborough", "Halton Hills", "Woolwich", "Erin", "Wellington North", "Wellesley", "Wilmot", "North Dumfries", "Guelph/Eramosa", "Centre Wellington", "Brantford", "Brant", "Norfolk", "Haldimand", "Dundas", "Hamilton", "Ancaster", "Dunnville"]);
  const theme = useTheme()
  const mobileBrowser = useMediaQuery(theme.breakpoints.down('sm'))

  const [checked, setChecked] = useState([])
  const [copyPaste, setCopyPaste] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const prices = currentListings.map(listing => listing.priceInt);
  const minPrice = Math.min(...prices); //min value of slider
  const maxPrice = Math.max(...prices);
  const sliderMax = 2500000

  const [showResidential, setShowResidential] = useState(true);
  const [showLand, setShowLand] = useState(false);  // set to true by default
  const [showFarm, setShowFarm] = useState(false);

  const [showFreehold, setShowFreehold] = useState(true); // true by default
  const [showCondo, setShowCondo] = useState(true);      // true by default

  const [showDetachedandSemi, setShowDetachedandSemi] = useState(true); // true by default
  const [showTownhouse, setShowTownhouse] = useState(true);      // true by default
  const [showApartment, setShowApartment] = useState(true);      // true by default

  const [showBungalow, setShowBungalow] = useState(true); // true by default
  const [showOnePointFive, setShowOnePointFive] = useState(true);      // true by default
  const [showTwoAndThree, setShowTwoAndThree] = useState(true);      // true by default
  const [showSplitLevel, setShowSplitLevel] = useState(true);      // true by default
  const [showMultiUnit, setShowMultiUnit] = useState(true);      // true by default
  const [showMobileHome, setShowMobileHome] = useState(false);      // true by default

  const classes = useStyles();
  const [sortMode, setSortMode] = useState('date');

  const [value, setValue] = useState([299000, 1500000]); //starting values of slider (within mid and max)

  const prevValueRef = useRef()
  prevValueRef.current = value[0]

  const handleSliderChange = (event, newValue) => {
    if (newValue[1] === 2500000) {
      return setValue([newValue[0], maxPrice])
    }
    if (newValue[0] !== prevValueRef.current) { // min value was changed
      setValue([newValue[0] - 1000, newValue[1]]);
    } else {
      setValue([newValue[0], newValue[1]]);
    }
  };

  function formatPrice(price) {
    if (price === sliderMax) {
      return `$${maxPrice / 1000000}M`;
    }
    if (price >= 1000000) {
      return `$${(price / 1000000).toFixed(2)}M`;
    } else if (price >= 1000) {
      return `$${(price / 1000).toFixed(0)}K`;
    }
    return `$${price}`;
  }

  useEffect(() => {
    // pull TREB listings from mongo
    if (user) {
      setCityState(user.defaultCheckedCities)
      axios.post('/api/current', { subscriptionType: user.subscriptionType, cities: user.cities, _id: user._id }).then(response => {
        const allListings = response.data;
        const filteredOutDuplicates = _.uniqBy(allListings, 'mls' && 'address')
        const removeHidden = filteredOutDuplicates.filter(listing => (listing.hide !== true))
        setCurrentListings(removeHidden)
      });
    }
  }, [user]);
  /*   useEffect(() => {
      // return all listings without login
      axios.get('/api/all').then(response => {
        const allListings = response.data;
        const filteredOutDuplicates = _.uniqBy(allListings, 'mls' && 'address')
        const removeHidden = filteredOutDuplicates.filter(listing => (listing.hide !== true))
        setCurrentListings(removeHidden)
      });
    }, []); */

  useEffect(() => {
    let copyPasteList = []
    for (let l of checked) {
      const match = filteredListingsByCity.find(listing => listing.mls === l)
      const obj = { price: match.price, address: match.address, city: match.city, link: match.link }
      copyPasteList.push(obj)
    }
    setCopyPaste(copyPasteList)// eslint-disable-next-line
  }, [checked])

  if (currentListings) {
    for (let listing of currentListings) {
      if (listing.date) {
        listing.da = new Date(chooseDate(listing.createdAt, listing.date))
      } else {
        listing.da = new Date(listing.createdAt)
      }
    }
  }

  const filteredListingsByCity = currentListings.filter(listing => {
    const isCityMatched = cityState.includes(listing.city);
    const isPriceInRange = listing.priceInt >= value[0] && listing.priceInt <= value[1];

    if (listing.use === 'Farm' && showFarm && isCityMatched && isPriceInRange) return true;
    if (listing.use === 'Land' && showLand && isCityMatched && isPriceInRange) return true;
    if ((listing.use === 'Unknown' || listing.type === "Duplex" || listing.type === "Triplex" || listing.type === "Fourplex") && showMultiUnit && isCityMatched && isPriceInRange) return true;

    const isTypeMatched = listing.use === 'Residential' && showResidential;

    if (listing.type === "Apartment" && showApartment && isCityMatched && isPriceInRange && showResidential && showCondo) return true;
    if (listing.type === "Mobile Home" && showMobileHome && isCityMatched && isPriceInRange) return true;

    const isOwnershipMatched =
      ((listing.ownership !== 'Condominium/Strata') && showFreehold) ||
      ((listing.ownership === 'Condominium/Strata' || listing.ownership === "Leasehold Condo/Strata") && showCondo && showTownhouse && listing.type !== "Apartment") ||
      ((listing.ownership === 'Condominium/Strata' || listing.ownership === "Leasehold Condo/Strata") && showCondo && listing.type === "House" && showDetachedandSemi)

    const isTypeMatched3 =
      (listing.ownership === "Condominium/Strata" && showCondo) ||
      (listing.stories === '1' && showBungalow && showFreehold && showDetachedandSemi && listing.type !== "Mobile Home") ||
      (listing.stories === '1.5' && showOnePointFive && showFreehold && showDetachedandSemi && listing.type !== "Mobile Home") ||
      ((listing.stories === '2' || listing.stories === "2.5" || listing.stories === '3') && listing.type !== 'Row / Townhouse' && showTwoAndThree && showFreehold && showDetachedandSemi) ||
      // ((listing.stories === '2' || listing.stories === "2.5" || listing.stories === '3') && showTwoAndThree && showFreehold && showDetachedandSemi) ||
      (listing.stories === '' && showSplitLevel && showFreehold && showDetachedandSemi && listing.type !== "Mobile Home") ||
      (listing.type === 'Row / Townhouse' && listing.ownership === "Freehold" && showTownhouse)// ||
    //  (listing.type === 'Row / Townhouse' && listing.ownership === "Freehold" && showTownhouse && !showDetachedandSemi)

    return isCityMatched && isPriceInRange && isTypeMatched && isOwnershipMatched && isTypeMatched3;
  });
  /* const filteredListingsMLS = new Set(filteredListingsByCity.map(listing => listing.mls))
  const notDisplaying = currentListings.filter(listing => !filteredListingsMLS.has(listing.mls))
  console.log("noDisplaying",notDisplaying)   */

  switch (sortMode) {
    case 'date':
      //sortedListings.sort((a, b) => b.da - a.da);
      filteredListingsByCity.sort((a, b) => b.da - a.da);
      break;
    case 'price':
      //sortedListings.sort((a, b) => a.priceInt - b.priceInt);
      filteredListingsByCity.sort((a, b) => a.priceInt - b.priceInt);
      break;
    default:
      break;
  }

  const [view, setView] = useState('card')

  const allCities = [];


  for (let listing of currentListings) {
    if (!allCities.includes(listing.city)) { //&& listing.city !== "Halton Hills"
      allCities.push(listing.city);
    }
  }


  const toggleCityState = e => {
    const target = e.target.checked; // true or false

    const name = e.target.name; // === 'Guelph'

    if (target) {
      setCityState([...cityState, name]);
    } else {
      setCityState(cityState.filter(city => city !== name));
    }
  };

  const toggleAllCityState = e => {
    if (cityState.length !== allCities.length) {
      // if atleast one checkbox not checked..
      setCityState(allCities);
    } else {
      // or else if they're all checked, then uncheck them all
      setCityState([]);
    }
  };

  const toggleChecked = (e) => {
    const name = e.target.name // === guelph
    if (e.target.checked) { //if its not already checked
      e.target.checked = true
      setChecked([...checked, name])
    } else {
      const newState = checked.filter(x => x !== name)
      setChecked(newState)
    }
  };

  const renderButtons = () => {
    if (view === 'card') {
      return (
        <span>
          <IconButton disabled={true} >
            <ViewWeekIcon />
          </IconButton>
          <IconButton onClick={() => setView('map')}>
            <MapOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => setView('list')}>
            <ViewListOutlinedIcon />
          </IconButton>

        </span>
      )
    }
    if (view === 'map') {
      return (
        <span>
          <IconButton onClick={() => setView('card')} >
            <ViewWeekOutlinedIcon />
          </IconButton>
          <IconButton disabled={true}>
            <MapIcon />
          </IconButton>
          <IconButton onClick={() => setView('list')}>
            <ViewListOutlinedIcon />
          </IconButton>

        </span>
      )
    } else {
      return (
        <span>
          <IconButton onClick={() => setView('card')}>
            <ViewWeekOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => setView('map')}>
            <MapOutlinedIcon />
          </IconButton>
          <IconButton disabled={true}>
            <ViewListIcon />
          </IconButton>

        </span>
      )
    }
  }

  const renderView = state => {
    switch (state) {
      case 'card':
        return (<div><div style={{ display: isLoading ? "block" : "none" }} ><LoadingCards /></div> <TrebGrid style={{ display: isLoading ? "none" : "" }} isLoading={isLoading} setIsLoading={setIsLoading} filteredListingsByCity={filteredListingsByCity} allCities={allCities} user={user} checked={checked} toggleChecked={toggleChecked} /></div>)
      case 'map':
        return (<Map filteredListingsByCity={filteredListingsByCity} checked={checked} />)
      case 'list':
        return (<TrebTable filteredListingsByCity={filteredListingsByCity} checked={checked} toggleChecked={toggleChecked} />)
      default:
        return 'card'
    }
  }



  if (signedIn && user?.subscribed) {
    //setCityState(user?.defaultCheckedCities)   infinite loop
    return (
      <div>
        {isLoading ? <CitySelectorLoading /> : <CitySelector allCities={allCities} toggleAllCityState={toggleAllCityState} toggleCityState={toggleCityState} cityState={cityState} currentListings={currentListings} />}
        <div style={{ display: isLoading ? "none" : "block" }} >
          <Typography gutterBottom>
            Price Range
          </Typography>
          <PriceSlider
            valueLabelFormat={val => formatPrice(val)}
            value={value}
            marks={[{ value: 1, label: "$0" }, { value: 500000, label: "$500K" }, { value: 750000 }, { value: 1000000, label: "$1M" }, { value: 1250000 }, { value: 1500000, label: "$1.5M" }, { value: 1725000 }, { value: 2000000, label: "$2M" }, { value: 2250000 }, { value: 2500000, label: "MAX" }]}
            onChange={handleSliderChange}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            min={0}
            max={sliderMax}
            step={50000}
          />
        </div>
        <Typography variant="h6">Displaying {filteredListingsByCity.length}/{currentListings.length} TREB listings</Typography>
        <Typography variant="subtitle1">Last updated at {currentListings.length > 1 ? dateToTime(currentListings[0].updatedAt) : ''}</Typography>
        <ToggleButtonGroup
          value={sortMode}
          exclusive
          onChange={(event, newSortMode) => setSortMode(newSortMode)}
          aria-label="sort mode"
        >
          <ToggleButton value="date" aria-label="sort by date">
            Date
          </ToggleButton>
          <ToggleButton value="price" aria-label="sort by price">
            Price
          </ToggleButton>
        </ToggleButtonGroup>
        <span style={{ right: '150px' }}>{renderButtons()}</span>
        <div style={{ borderStyle: "solid", borderColor: "lightgray", display: "inline-block", paddingLeft: "8px", borderRadius: "10px", marginRight: "8px", marginBottom: "8px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showResidential}
                onChange={() => setShowResidential(!showResidential)}
                color="primary"
              />
            }
            label="Residential"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showMultiUnit}
                onChange={() => setShowMultiUnit(!showMultiUnit)}
                color="primary"
              />
            }
            label="Multi-Unit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showLand}
                onChange={() => setShowLand(!showLand)}
                color="primary"
              />
            }
            label="Land"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showFarm}
                onChange={() => setShowFarm(!showFarm)}
                color="primary"
              />
            }
            label="Farm"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={showMobileHome}
                onChange={() => setShowMobileHome(!showMobileHome)}
                color="primary"
              />
            }
            label="Mobile Home"
          />

        </div>
        <div style={{ borderStyle: "solid", borderColor: "lightgray", display: "inline-block", paddingLeft: "8px", borderRadius: "10px", marginRight: "8px", marginBottom: "8px" }}
          className={(showResidential && !showFreehold && !showCondo) ? classes.flashGreen : ""}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={showFreehold}
                onChange={() => setShowFreehold(!showFreehold)}
                color="primary"
                inputProps={{ 'aria-label': 'Filter freehold listings' }}
                disabled={!showResidential}
              />
            }
            label="Freehold"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showCondo}
                onChange={() => setShowCondo(!showCondo)}
                color="primary"
                inputProps={{ 'aria-label': 'Filter condo listings' }}
                disabled={!showResidential}
              />
            }
            label="Condo"
          />
        </div>
        <div style={{ borderStyle: "solid", borderColor: "lightgray", display: "inline-block", paddingLeft: "8px", borderRadius: "10px", marginRight: "8px", marginBottom: "8px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showDetachedandSemi}
                onChange={() => setShowDetachedandSemi(!showDetachedandSemi)}
                color="primary"
                disabled={!showResidential || !showFreehold}

              />
            }
            label="Detached/Semi"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showTownhouse}
                onChange={() => setShowTownhouse(!showTownhouse)}
                color="primary"
                disabled={!showResidential || (!showFreehold && !showCondo)}

              />
            }
            //label="Townhouse"
            label={mobileBrowser ? "Town" : "Townhouse"}

          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showApartment}
                onChange={() => setShowApartment(!showApartment)}
                color="primary"
                disabled={!showResidential || !showCondo}

              />
            }
            label="Apartment"
          />
        </div>
        <div style={{ borderStyle: "solid", borderColor: "lightgray", display: "inline-block", paddingLeft: "8px", borderRadius: "10px", marginRight: "8px", marginBottom: "8px" }}
          className={(showDetachedandSemi && !showBungalow && !showOnePointFive && !showTwoAndThree && !showSplitLevel) ? classes.flashGreen : ""}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={showBungalow}
                onChange={() => setShowBungalow(!showBungalow)}
                color="primary"
                disabled={!showResidential || !showFreehold || !showDetachedandSemi}

              />
            }
            label="Bungalow"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showOnePointFive}
                onChange={() => setShowOnePointFive(!showOnePointFive)}
                color="primary"
                disabled={!showResidential || !showFreehold || !showDetachedandSemi}

              />
            }
            label="1.5 Storey"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showTwoAndThree}
                onChange={() => setShowTwoAndThree(!showTwoAndThree)}
                color="primary"
                disabled={!showResidential || !showFreehold || !showDetachedandSemi}

              />
            }
            label="2 & 3 Storey"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showSplitLevel}
                onChange={() => setShowSplitLevel(!showSplitLevel)}
                color="primary"
                disabled={!showResidential || !showFreehold || !showDetachedandSemi}
              />
            }
            label="Split Level"
          />
        </div>

        {/* <span style={{right: '150px'}}>{renderButtons()}</span> */}
        {renderView(view)}
        {checked ?
          <>
            {view !== "map" ? <Typography variant='subtitle1'>Check off listings to generate a list of links that you can easily forward to your clients</Typography> : ""}
            {copyPaste.map(l => {
              return (
                <div key={l.address}>
                  <Typography variant='subtitle2'>{l.address}  {l.city}</Typography>
                  <Typography variant='subtitle2'>{l.price}</Typography>
                  <Typography variant='subtitle2'>{l.link}</Typography>
                  <br />
                </div>
              )
            })
            }
          </>
          : ''}
      </div>
    )
  } else {
    return (
      <div>

      </div>
    )
  }

}

export default Landing

//     axios.delete('/api/delete')
//     axios.get('/api/refresh')

//<Button onClick={() => axios.get('/api/refresh')}>Refresh</Button>

//{mapView ? <Map filteredListingsByCity={filteredListingsByCity} /> : <TrebGrid  filteredListingsByCity={filteredListingsByCity} />}

// Last updated at {currentListings.length > 1 ? dateToTime(currentListings[0].updatedAt)

//Original sorting algo - Was only using photochange so I changed to utilize chooseDate()
// I could reimplement this if I loop through and added a new prop for the actual date to use when i GET current listings
/* const filteredListingsByCity = currentListings.filter(listing => cityState.includes(listing.city))
filteredListingsByCity.sort((a, b) => {
   const date1 = new Date(b.date);
   const date2 = new Date(a.date);
   a.date = date2;
   b.date = date1;
   return b.date - a.date;
}); */
